import React, { useState, createContext, useCallback, useEffect, useRef } from "react";

import { navigate, Location } from "@reach/router";
import { graphql, StaticQuery } from "gatsby";
import Cookies from "universal-cookie";

// const checkLanguageRegionIsInPathnameArray = (supportedLanguageRegions, pathname) => {
//     return supportedLanguageRegions.some(r => pathname.split("/").includes(r))
// }

const getIndexOfLanguageRegionInPathnameArray = (supportedLanguageRegions, pathname) => {
    if (pathname && supportedLanguageRegions) {
        const indexOfSupportedLanguageRegions = pathname.split("/")
            .map((r, index) => {
                if (supportedLanguageRegions.includes(r)) { return index; }
            })
            .filter((item) => item > 0);

        return {
            max: indexOfSupportedLanguageRegions.length > 0 && Math.max(...indexOfSupportedLanguageRegions),
            min: indexOfSupportedLanguageRegions.length > 0 && Math.min(...indexOfSupportedLanguageRegions),
            length: indexOfSupportedLanguageRegions.length,
        };
    }
};

const cleanArray = (path) => {
    if (path && path.length > 0) {
        return path.filter(x => !!x);
    }

    return ["/"];
};

export const changeLanguageRegionPath = ({ pathname, newLanguageRegion, supportedLanguageRegions }) => {
    const newPathItem = newLanguageRegion === "en-us" ? null : newLanguageRegion;

    let editedPath = [];
    if (pathname) {
        if (newPathItem) {
            const indexOfLanguage = getIndexOfLanguageRegionInPathnameArray(supportedLanguageRegions, pathname);

            if (indexOfLanguage && indexOfLanguage.min) {
                const path = pathname.split("/");
                path[indexOfLanguage.min] = newPathItem;
                editedPath = path;
            } else {
                const path = pathname.split("/");
                editedPath = [path[0], newPathItem, ...path.splice(1)];
            }

        } else {
            editedPath = pathname.split("/").filter(languageRegion => !supportedLanguageRegions.includes(languageRegion));
        }

        const cleanPath = cleanArray(editedPath);
        const joinedPath = cleanPath.join("/");

        return joinedPath;
    }

    return "/";
};

const checkLanguageRegionMatchesConfig = (supportedLanguageRegions, newLanguageRegion) => {
    return supportedLanguageRegions.includes(newLanguageRegion);
};

const setLanguageRegionCookie = (newLanguageRegion) => {
    const cookies = new Cookies();

    cookies.set("language-region-override", newLanguageRegion, { path: "/" });
};

const defaultContext = {
    languageRegion: "en-us",
    updateLanguageRegion: () => { }
};

export const LanguageRegionContext = createContext(defaultContext);

export const Provider = ({ children, supportedLanguageRegions }) => {
    const [languageRegion, setLanguageRegion] = useState("en-us");
    const initialLanguageRegionLoaded = useRef(false);

    const updateLanguageRegion = useCallback(
        async ({ newLanguageRegion, location }) => {
            if (!newLanguageRegion ||
                initialLanguageRegionLoaded.current && newLanguageRegion === languageRegion ||
                !supportedLanguageRegions ||
                supportedLanguageRegions.length === 0 ||
                !checkLanguageRegionMatchesConfig(supportedLanguageRegions, newLanguageRegion)
            ) return;

            initialLanguageRegionLoaded.current = true;
            setLanguageRegion(newLanguageRegion);
            setLanguageRegionCookie(newLanguageRegion);

            if (location && location.pathname) {
                const newPath = changeLanguageRegionPath({ pathname: location.pathname, newLanguageRegion, supportedLanguageRegions });

                if (newPath) {
                    navigate(`/${newPath}`);
                } else {
                    if (newLanguageRegion === "en-us") {
                        navigate("/");
                    } else {
                        navigate(`/${newLanguageRegion}`);
                    }

                }
            }

        },
        [languageRegion]
    );

    useEffect(() => {
        updateLanguageRegion(languageRegion);
    }, [languageRegion, updateLanguageRegion]);

    return (
        <LanguageRegionContext.Provider value={{
            languageRegion,
            updateLanguageRegion
        }}>
            {children}
        </LanguageRegionContext.Provider>
    );
};

const LanguageRegionDetector = (props) => {
    const { languageRegion, updateLanguageRegion } = props.languageRegionContext;
    const { location } = props;

    useEffect(() => {
        if (location && location.pathname) {
            const currentLanguageRegionUrlPath = location.pathname.split("/", 2)[1];

            if (currentLanguageRegionUrlPath !== languageRegion) {
                updateLanguageRegion({ newLanguageRegion: currentLanguageRegionUrlPath });
            }
        }
    }, [location]);

    return (<>{props.children}</>);
};

export const LanguageRegionContextProvider = (props) => {
    return (
        <StaticQuery
            query={graphql`
                query {
                    site {
                        siteMetadata {
                            supportedLanguageRegions
                        }
                    }
                }
            `}
            render={data => {
                const supportedLanguageRegions = (data && data.site && data.site.siteMetadata && data.site.siteMetadata.supportedLanguageRegions);

                return (
                    <Location>
                        {({ location }) => {
                            return (
                                <Provider supportedLanguageRegions={supportedLanguageRegions}>
                                    <LanguageRegionContext.Consumer>
                                        {languageRegionContext =>
                                            <LanguageRegionDetector location={location} languageRegionContext={languageRegionContext}>
                                                {props.children}
                                            </LanguageRegionDetector>}
                                    </LanguageRegionContext.Consumer>
                                </Provider>
                            );
                        }}
                    </Location>
                );
            }}
        />
    );
};