import React from "react";

// import {ApolloProvider} from "@apollo/client";

// import { client } from "./apollo";
import { LanguageRegionContextProvider } from "./src/context-providers/language-region-context";
import { MenuContextProvider } from "./src/context-providers/menu-context";

export const wrapRootElement = ({ element }) => {
    return (
        // <ApolloProvider client={client}>
        <LanguageRegionContextProvider>
            <MenuContextProvider>
                {element}
            </MenuContextProvider>
        </LanguageRegionContextProvider>
        // </ApolloProvider>
    );
};

// export const shouldUpdateScroll = ({ routerProps }) => {
//     const { disableScrollUpdate } = routerProps.location.state
//     return !disableScrollUpdate
// }

// trigger an immediate page refresh when an update is found
export const onServiceWorkerUpdateReady = () => window.location.reload();

export const onRouteUpdate = loc => {
    const { state } = loc.location;
    if (state && state.refocusId) {
        const elem = document.getElementById(state.refocusId);
        if (elem) {
            elem.focus();
        }
    }
};