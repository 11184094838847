import React, { useState, createContext } from "react";

export const MenuContext = createContext({
    isDesktopProductFiltersOpen: true,
    updateIsDesktopProductFiltersOpen: () => { },
    isProductFiltersOpen: false,
    updateIsProductFiltersOpen: () => { },
});

const Provider = (props) => {
    const [isDesktopProductFiltersOpen, setIsDesktopProductFiltersOpen] = useState(true);
    const [isProductFiltersOpen, setIsProductFiltersOpen] = useState(false);

    return (
        <MenuContext.Provider
            value={{
                isDesktopProductFiltersOpen,
                updateIsDesktopProductFiltersOpen: (newIsDesktopProductFiltersOpen) => setIsDesktopProductFiltersOpen(newIsDesktopProductFiltersOpen),
                isProductFiltersOpen,
                updateIsProductFiltersOpen: (newIsProductFiltersOpen) => setIsProductFiltersOpen(newIsProductFiltersOpen),
            }}
        >
            {props.children}
        </MenuContext.Provider>
    );
};


export const MenuContextProvider = (props) => {
    return (
        <Provider>{props.children}</Provider>
    );
};