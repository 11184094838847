// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-us-index-js": () => import("./../../../src/templates/about-us/index.js" /* webpackChunkName: "component---src-templates-about-us-index-js" */),
  "component---src-templates-affiliate-disclosure-index-js": () => import("./../../../src/templates/affiliate-disclosure/index.js" /* webpackChunkName: "component---src-templates-affiliate-disclosure-index-js" */),
  "component---src-templates-article-index-js": () => import("./../../../src/templates/article/index.js" /* webpackChunkName: "component---src-templates-article-index-js" */),
  "component---src-templates-articles-index-js": () => import("./../../../src/templates/articles/index.js" /* webpackChunkName: "component---src-templates-articles-index-js" */),
  "component---src-templates-category-index-js": () => import("./../../../src/templates/category/index.js" /* webpackChunkName: "component---src-templates-category-index-js" */),
  "component---src-templates-contact-us-index-js": () => import("./../../../src/templates/contact-us/index.js" /* webpackChunkName: "component---src-templates-contact-us-index-js" */),
  "component---src-templates-home-index-js": () => import("./../../../src/templates/home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */)
}

